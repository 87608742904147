import { GatsbyBrowser } from "gatsby";
import "./static/fonts/fonts.css";

export const onRouteUpdate: GatsbyBrowser["onRouteUpdate"] = (
  args,
  options
) => {
  const gtag = window["gtag"];
  if (typeof gtag !== "function") return;

  const location = args.location;
  const path = location
    ? location.pathname + location.search + location.hash
    : null;

  if (path) {
    gtag("event", "page_view", { page_path: path });
  }

  // This is to support existing tags in google tag manager
  gtag("event", "gatsby-route-change");
};
