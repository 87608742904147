exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-plans-embed-tsx": () => import("./../../../src/pages/plansEmbed.tsx" /* webpackChunkName: "component---src-pages-plans-embed-tsx" */),
  "component---src-pages-press-news-tsx": () => import("./../../../src/pages/press-news.tsx" /* webpackChunkName: "component---src-pages-press-news-tsx" */),
  "component---src-pages-signin-tsx": () => import("./../../../src/pages/signin.tsx" /* webpackChunkName: "component---src-pages-signin-tsx" */),
  "component---src-pages-upgrade-error-tsx": () => import("./../../../src/pages/upgrade-error.tsx" /* webpackChunkName: "component---src-pages-upgrade-error-tsx" */),
  "component---src-pages-website-privacy-policy-celo-tsx": () => import("./../../../src/pages/website-privacy-policy-celo.tsx" /* webpackChunkName: "component---src-pages-website-privacy-policy-celo-tsx" */),
  "component---src-templates-about-template-tsx": () => import("./../../../src/templates/aboutTemplate.tsx" /* webpackChunkName: "component---src-templates-about-template-tsx" */),
  "component---src-templates-blog-template-tsx": () => import("./../../../src/templates/blog-template.tsx" /* webpackChunkName: "component---src-templates-blog-template-tsx" */),
  "component---src-templates-careers-template-tsx": () => import("./../../../src/templates/careersTemplate.tsx" /* webpackChunkName: "component---src-templates-careers-template-tsx" */),
  "component---src-templates-case-study-template-tsx": () => import("./../../../src/templates/case-study-template.tsx" /* webpackChunkName: "component---src-templates-case-study-template-tsx" */),
  "component---src-templates-celo-vs-whatsapp-template-tsx": () => import("./../../../src/templates/celoVsWhatsappTemplate.tsx" /* webpackChunkName: "component---src-templates-celo-vs-whatsapp-template-tsx" */),
  "component---src-templates-contact-template-tsx": () => import("./../../../src/templates/contactTemplate.tsx" /* webpackChunkName: "component---src-templates-contact-template-tsx" */),
  "component---src-templates-covid-19-template-tsx": () => import("./../../../src/templates/covid19Template.tsx" /* webpackChunkName: "component---src-templates-covid-19-template-tsx" */),
  "component---src-templates-customers-template-tsx": () => import("./../../../src/templates/customersTemplate.tsx" /* webpackChunkName: "component---src-templates-customers-template-tsx" */),
  "component---src-templates-downloads-template-tsx": () => import("./../../../src/templates/downloadsTemplate.tsx" /* webpackChunkName: "component---src-templates-downloads-template-tsx" */),
  "component---src-templates-enterprise-template-tsx": () => import("./../../../src/templates/enterpriseTemplate.tsx" /* webpackChunkName: "component---src-templates-enterprise-template-tsx" */),
  "component---src-templates-leadership-template-tsx": () => import("./../../../src/templates/leadershipTemplate.tsx" /* webpackChunkName: "component---src-templates-leadership-template-tsx" */),
  "component---src-templates-legal-template-tsx": () => import("./../../../src/templates/legalTemplate.tsx" /* webpackChunkName: "component---src-templates-legal-template-tsx" */),
  "component---src-templates-partners-inner-tsx": () => import("./../../../src/templates/partners-inner.tsx" /* webpackChunkName: "component---src-templates-partners-inner-tsx" */),
  "component---src-templates-partners-landing-template-tsx": () => import("./../../../src/templates/partnersLandingTemplate.tsx" /* webpackChunkName: "component---src-templates-partners-landing-template-tsx" */),
  "component---src-templates-security-compliance-template-tsx": () => import("./../../../src/templates/securityComplianceTemplate.tsx" /* webpackChunkName: "component---src-templates-security-compliance-template-tsx" */),
  "component---src-templates-security-template-tsx": () => import("./../../../src/templates/securityTemplate.tsx" /* webpackChunkName: "component---src-templates-security-template-tsx" */),
  "component---src-templates-thankyou-template-tsx": () => import("./../../../src/templates/thankyouTemplate.tsx" /* webpackChunkName: "component---src-templates-thankyou-template-tsx" */),
  "component---src-templates-v-3-features-page-tsx": () => import("./../../../src/templates/v3/featuresPage.tsx" /* webpackChunkName: "component---src-templates-v-3-features-page-tsx" */),
  "component---src-templates-v-3-home-page-tsx": () => import("./../../../src/templates/v3/homePage.tsx" /* webpackChunkName: "component---src-templates-v-3-home-page-tsx" */)
}

